export const data = [
  {
    title: "區塊鏈應用顧問",
    icon: "blockChain",
    list: [
      "產官學區塊鏈防詐騙宣導。",
      "協助網站 UI/UX 優化",
      "輔導企業 NFT 賦能的廣度及深度。",
      "智能合約動作及用途釋義。",
      "協助企業申請簡易台幣支付服務。",
      "輔導審計報告送審。",
      "輔導智能合約上架。",
      "輔導加密貨幣架上 CoinMarketCap。",
      "流量礦機服務",
    ],
  },
  {
    title: "智能合約",
    icon: "contract",
    list: [
      "智能合約動作及用途釋義。",
      "協助使用智能合約錢包新增SDK 使用功能。",
      "輔導智能合約上架 DappRadar。",
      "輔導加密貨幣上架 CoinMarketCap。",
      "輔導企業發幣。",
      "NFT 公售網站製作。",
      "NFT 二級市場買賣平台製作。",
      "盲盒破解防護、及智能合約漏洞輔導。",
      "DeFi、Swap 合約製作。",
      "偵查後門系統。",
      "二元遊戲製作(消耗代幣使用)。",
      "智能合約開發推薦獎勵代數。",
      "系統維運/月。",
    ],
  },
  {
    title: "國際反洗錢",
    icon: "money",
    list: [
      "NFT 發行後市值管理與規劃、審計報告、融資規劃、項目估值、相關信託或託管安排、經濟模型建置等市場分析評估等諮詢。",
      "協助遵循金管銀法字第 11002739741 號最新規定。",
      "協助遵循「虛擬通貨平台及交易業務事業防制洗錢及打擊資恐辦法」針對發行方公司端、專責人員與業務人員之法遵要求、報告要求等相關應注意義務。",
      "其餘 NFT 國內外相關法規與解釋函令。如主管機關公布之要點或實務參考作法中有關區塊鏈平台規格是否符合內控要求、佈署機制、交易寫入分散式帳本時機及寫入規則、資料存取權限控管機制程式碼自動執行內容協議之評估與審核發佈機制與程序、交易日期、電子錢包轉出位址、電子錢包轉入位址、交易數量等資訊揭露情形之相關法規、財稅、技術顧問諮詢。",
    ],
  },
  {
    title: "行銷規劃",
    icon: "marketing",
    list: [
      "SEO 廣告投放。",
      "DC 頻道建立與經營(中英文)。",
      "協助公售前的熱度活動安排(給予實際案例)。",
      "項目對接 (香港、紐約、韓國、日本、馬來西亞)。",
      "協辦線上 AMA 舉辦。",
      "協助項目露出，安排參「區塊鏈高峰會」。",
      "LINE官方帳號設計規劃",
      "推薦予各大KOL 洽談合作。",
      "協助建立社群每月增加有效入購買者 500 及有效活動參與人 2000。",
      "引薦媒體資源，舉辦項目簡報說明會。",
      "引薦國內外知名投資人。",
      "協助準備融資簡報。",
      "協助申請各類政府專案補助。",
    ],
  },
];
