import React from "react";
import { Link } from "react-router-dom";

import classes from "./Footer.module.css";

import FacebookSvg from "../../components/UI/Svg/FacebookSvg";
import DiscordSvg from "../../components/UI/Svg/DiscordSvg";
import InstragramSvg from "../../components/UI/Svg/InstragramSvg";
import TwitterSvg from "../../components/UI/Svg/TwitterSvg";

const Footer: React.FC = () => {
  return (
    <footer className={classes.footer}>
      <Link to="/">
        <FacebookSvg />
      </Link>
      <Link to="/">
        <DiscordSvg />
      </Link>
      <Link to="/">
        <InstragramSvg />
      </Link>
      <Link to="/">
        <TwitterSvg />
      </Link>
    </footer>
  );
};

export default Footer;
