import React from "react";

const TwitterSvg: React.FC = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M26.5501 7.83617C25.6852 8.21935 24.7677 8.47074 23.8282 8.58197C24.8178 7.98989 25.5583 7.05821 25.9117 5.96057C24.9816 6.51274 23.9638 6.90156 22.9024 7.11017C22.459 6.6372 21.9231 6.26033 21.3281 6.00293C20.733 5.74553 20.0915 5.61307 19.4431 5.61377C16.8259 5.61377 14.7037 7.73537 14.7037 10.3532C14.7037 10.7243 14.7457 11.0864 14.8267 11.4335C10.8877 11.2358 7.39572 9.34877 5.05812 6.48107C4.63664 7.20439 4.41517 8.02681 4.41642 8.86397C4.4158 9.64428 4.60793 10.4126 4.97575 11.1008C5.34357 11.789 5.87569 12.3757 6.52482 12.8087C5.77233 12.7848 5.03642 12.5815 4.37832 12.2159C4.37772 12.2357 4.37772 12.2552 4.37772 12.2756C4.37772 14.5712 6.01122 16.4867 8.17902 16.9226C7.48099 17.1115 6.74913 17.1392 6.03882 17.0036C6.64182 18.8861 8.39202 20.2568 10.4659 20.2952C8.78709 21.6127 6.71397 22.3273 4.57992 22.3241C4.19742 22.3241 3.82032 22.3019 3.44922 22.2578C5.61622 23.6501 8.13826 24.3892 10.714 24.3866C19.4314 24.3866 24.1984 17.1653 24.1984 10.9031C24.1984 10.6979 24.1936 10.493 24.1846 10.2893C25.113 9.61904 25.9141 8.78828 26.5501 7.83617V7.83617Z"
        fill="white"
      />
    </svg>
  );
};

export default TwitterSvg;
