import React from "react";
import LandingPage from "../components/LandingPage/LandingPage";

import { data } from "../data/home";

const HomePage: React.FC = () => {
  return <LandingPage data={data} />;
};

export default HomePage;
