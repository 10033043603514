import React from "react";

const MoneySvg: React.FC = () => {
  return (
    <svg
      width="98"
      height="98"
      viewBox="0 0 98 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.0417 28.175C58.31 28.175 61.005 31.6458 61.25 36.75H70.2742C69.9884 29.7267 65.7009 23.275 57.1667 21.1925V12.25H44.9167V21.07C42.7525 21.56 40.7109 22.295 38.8734 23.275L44.8759 29.2775C46.55 28.5833 48.5917 28.175 51.0417 28.175ZM21.7642 16.5783L16.5784 21.7642L30.625 35.8108C30.625 44.3042 36.995 48.9183 46.5909 51.7767L60.9234 66.1092C59.535 68.0692 56.6359 69.825 51.0417 69.825C42.63 69.825 39.3225 66.0683 38.8734 61.25H29.89C30.38 70.1925 37.0767 75.215 44.9167 76.8892V85.75H57.1667V76.9708C61.0867 76.2358 64.5984 74.725 67.1709 72.3975L76.2359 81.4625L81.4217 76.2767L21.7642 16.5783Z"
        fill="white"
      />
    </svg>
  );
};

export default MoneySvg;
