import React from "react";

import LandingPage from "../components/LandingPage/LandingPage";

import { data } from "../data/guide";

const GuidePage: React.FC = () => {
  return <LandingPage data={data} />;
};

export default GuidePage;
