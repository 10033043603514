import {
  createBrowserRouter,
  RouterProvider,
  createHashRouter,
} from "react-router-dom";
import Container from "./layout/Container";
import ConsultPage from "./pages/ConsultPage";
import GuidePage from "./pages/GuidePage";
import HomePage from "./pages/HomePage";

const router = createHashRouter([
  {
    path: "/",
    element: <Container />,
    children: [
      { path: "/", element: <HomePage /> },
      { path: "/guide", element: <GuidePage /> },
      { path: "/consult", element: <ConsultPage /> },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
