import React from "react";

const FacebookSvg: React.FC = () => {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.7305 0H1.26947C0.932788 0 0.609892 0.133748 0.37182 0.37182C0.133748 0.609892 0 0.932788 0 1.26947V21.7308C8.4708e-05 22.0675 0.13387 22.3903 0.371933 22.6283C0.609996 22.8663 0.932843 23 1.26947 23H12.2849V14.0933H9.28753V10.6222H12.2849V8.06214C12.2849 5.09131 14.0993 3.47396 16.7494 3.47396C18.0189 3.47396 19.1098 3.56819 19.428 3.61068V6.71536L17.5899 6.71632C16.1486 6.71632 15.8697 7.40121 15.8697 8.40618V10.6225H19.3069L18.8594 14.0936H15.8697V23H21.7308C22.0675 22.9999 22.3903 22.8661 22.6283 22.6281C22.8663 22.39 23 22.0672 23 21.7305V1.26947C23 0.932788 22.8663 0.609892 22.6282 0.37182C22.3901 0.133748 22.0672 0 21.7305 0V0Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookSvg;
