import React, { useRef } from "react";
import { Link } from "react-router-dom";

import classes from "./Header.module.css";

import logo from "../../images/logo.webp";

interface Props {
  curPath: string;
}

const Header: React.FC<Props> = ({ curPath }) => {
  const listRef = useRef<HTMLUListElement>(null);

  return (
    <header className={classes.header}>
      <img src={logo} alt="polai" />
      <ul ref={listRef} className="nav-list">
        <li>
          <Link to={{ pathname: "/" }}>關於鉑萊</Link>
        </li>
        <li>
          <Link to={{ pathname: "/guide" }}>何謂RNFT?</Link>
        </li>
        <li>
          <Link to={{ pathname: "/consult" }}>顧問服務</Link>
        </li>
      </ul>
    </header>
  );
};

export default Header;
