import React from "react";

const MarketingSvg: React.FC = () => {
  return (
    <svg
      width="89"
      height="91"
      viewBox="0 0 89 91"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_884_1262)">
        <path
          d="M79.2656 11.9437C78.3094 11.3111 77.2157 10.9285 76.0809 10.8293C74.9461 10.7301 73.8049 10.9175 72.7575 11.375L38.4369 25.1956C37.6243 25.5337 36.7554 25.7075 35.8781 25.7074H13.9062C12.0622 25.7074 10.2936 26.4565 8.98965 27.7897C7.68568 29.123 6.95313 30.9313 6.95312 32.8168V33.3856H0V50.4481H6.95312V51.1874C6.9967 53.0432 7.74845 54.808 9.04772 56.1046C10.347 57.4012 12.0907 58.1267 13.9062 58.1262L22.25 76.2125C22.815 77.4304 23.7045 78.4607 24.8163 79.185C25.9282 79.9094 27.2173 80.2984 28.5356 80.3075H32.04C33.8744 80.2924 35.6287 79.5368 36.9207 78.2052C38.2126 76.8735 38.9376 75.0738 38.9375 73.1981V58.8087L72.7575 72.6293C73.5894 72.9678 74.4766 73.1415 75.3719 73.1412C76.7609 73.1183 78.1135 72.6836 79.2656 71.89C80.1805 71.2583 80.9352 70.4131 81.4672 69.424C81.9992 68.435 82.2932 67.3307 82.325 66.2025V17.8018C82.3199 16.6445 82.0386 15.506 81.5054 14.4851C80.9722 13.4642 80.2034 12.5918 79.2656 11.9437V11.9437ZM31.9844 32.8168V51.1874H13.9062V32.8168H31.9844ZM31.9844 73.1981H28.48L21.6381 58.1262H31.9844V73.1981ZM40.9956 52.0406C40.3352 51.6955 39.6465 51.41 38.9375 51.1874V32.4187C39.6397 32.2706 40.3283 32.0612 40.9956 31.7931L75.3719 17.8018V66.0318L40.9956 52.0406ZM82.4919 34.8074V49.0262C84.336 49.0262 86.1045 48.2772 87.4085 46.9439C88.7124 45.6106 89.445 43.8023 89.445 41.9168C89.445 40.0313 88.7124 38.223 87.4085 36.8897C86.1045 35.5565 84.336 34.8074 82.4919 34.8074V34.8074Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_884_1262">
          <rect width="89" height="91" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MarketingSvg;
