import React, { useEffect } from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Outlet, useLocation } from "react-router-dom";

import classes from "./Container.module.css";

interface Props {}

const Container: React.FC<Props> = () => {
  const { pathname } = useLocation();

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);

  useEffect(() => {
    const resizeHandler = () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    // We listen to the resize event
    window.addEventListener("resize", resizeHandler);

    return window.removeEventListener("resize", resizeHandler);
  }, []);

  return (
    <div className={classes.container}>
      <Header curPath={pathname} />
      <div className={classes.main}>
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default Container;
