import React from "react";

import classes from "./LandingPage.module.css";

interface Props {
  data: { title: string; desc: string; image: string };
}

const LandingPage: React.FC<Props> = ({ data }) => {
  const { title, desc, image } = data;

  return (
    <section className={classes.page}>
      <h1>{title}</h1>
      <p>{desc}</p>
      <div className={classes.imgContainer}>
        <img src={image} alt={title} />
      </div>
    </section>
  );
};

export default LandingPage;
