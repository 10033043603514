import React from "react";

const InstragramSvg: React.FC = () => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5 3.75H7.5C5.375 3.75 3.75 5.375 3.75 7.5V22.5C3.75 24.625 5.375 26.25 7.5 26.25H22.5C24.625 26.25 26.25 24.625 26.25 22.5V7.5C26.25 5.375 24.625 3.75 22.5 3.75ZM15 11.25C17.125 11.25 18.75 12.875 18.75 15C18.75 17.125 17.125 18.75 15 18.75C12.875 18.75 11.25 17.125 11.25 15C11.25 12.875 12.875 11.25 15 11.25ZM19.75 8.75C19.75 7.875 20.5 7.25 21.25 7.25C22 7.25 22.75 8 22.75 8.75C22.75 9.5 22.125 10.25 21.25 10.25C20.375 10.25 19.75 9.625 19.75 8.75V8.75ZM22.5 23.75H7.5C6.75 23.75 6.25 23.25 6.25 22.5V15H8.75C8.75 18.5 11.5 21.25 15 21.25C18.5 21.25 21.25 18.5 21.25 15H23.75V22.5C23.75 23.25 23.25 23.75 22.5 23.75Z"
        fill="white"
      />
    </svg>
  );
};

export default InstragramSvg;
