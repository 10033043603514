import React from "react";

import classes from "./ConsultBackground.module.css";

interface Props {
  title: string;
  src: string;
}

const ConsultBackground: React.FC<Props> = ({ title, src }) => {
  return (
    <div className={classes.container}>
      <img src={src} alt={title} />
    </div>
  );
};

export default ConsultBackground;
