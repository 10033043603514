import React, { useState, useRef } from "react";

import classes from "./ConsultList.module.css";

import ConsultItem from "./ConsultItem/ConsultItem";
import { data } from "../../data/consults";
import ConsultBackground from "./ConsultBackground/ConsultBackground";

import consultDefaultImage from "../../images/consult-default.webp";
import consult1Image from "../../images/consult-1.webp";
import consult2Image from "../../images/consult-2.webp";
import consult3Image from "../../images/consult-3.webp";
import consult4Image from "../../images/consult-4.webp";

const ConsultList: React.FC = () => {
  const [curActive, setCurActive] = useState(-1);
  const containerRef = useRef<HTMLDivElement>(null);
  const isMediumScreen =
    window && window.innerWidth > 600 && window.innerWidth < 1000;
  const containerHeight = containerRef.current
    ? containerRef.current.offsetHeight
    : 480;
  const containerWidth = containerRef.current
    ? containerRef.current.offsetWidth
    : 600;

  const data1 = data.slice(0, 2);
  const data2 = data.slice(2);

  const firstRowActive = curActive >= 0 && curActive < 2;
  const secondRowActive = curActive >= 0 && curActive > 1;

  // interface WrapperProps {
  //   children: React.ReactNode;
  //   className: string;
  //   style: any;
  // }

  // const Wrapper: React.FC<WrapperProps> = ({ children, className, style }) => {
  //   if (isMediumScreen) {
  //     return (
  //       <div className={className} style={style}>
  //         {children}
  //       </div>
  //     );
  //   }
  //   return <>{children}</>;
  // };

  let content = [];
  if (isMediumScreen) {
    content = [data1, data2].map((d, pi) => (
      <div
        key={d[0].title}
        className={classes.row}
        style={
          (firstRowActive && pi === 1) || (secondRowActive && pi === 0)
            ? { width: 0 }
            : (firstRowActive && pi === 0) || (secondRowActive && pi === 1)
            ? { width: "100%" }
            : {}
        }
      >
        {d.map((d, i) => (
          <ConsultItem
            key={d.title}
            data={d}
            index={i + pi * 2}
            curActive={curActive}
            setCurActive={setCurActive}
            containerHeight={containerHeight}
            containerWidth={containerWidth}
          />
        ))}
      </div>
    ));
  } else {
    content = data.map((d, i) => (
      <ConsultItem
        key={d.title}
        data={d}
        index={i}
        curActive={curActive}
        setCurActive={setCurActive}
        containerHeight={containerHeight}
        containerWidth={containerWidth}
      />
    ));
  }

  let imageSrc = consultDefaultImage;
  switch (curActive) {
    case 0:
      imageSrc = consult1Image;
      break;
    case 1:
      imageSrc = consult2Image;
      break;
    case 2:
      imageSrc = consult3Image;
      break;
    case 3:
      imageSrc = consult4Image;
      break;
    default:
      break;
  }

  return (
    <div className={classes.list} ref={containerRef}>
      {content}
      <ConsultBackground title={"顧問服務"} src={imageSrc} />
    </div>
  );
};

export default ConsultList;
