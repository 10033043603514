import React, { useRef } from "react";
import BlockChainSvg from "../../UI/Svg/BlockChainSvg";
import ContractSvg from "../../UI/Svg/ContractSvg";
import MarketingSvg from "../../UI/Svg/MarketingSvg";
import MoneySvg from "../../UI/Svg/MoneySvg";
import MoreSvg from "../../UI/Svg/MoreSvg";

import classes from "./ConsultItem.module.css";

interface Props {
  data: { title: string; icon: string; list: string[] };
  index: number;
  curActive: number;
  setCurActive: React.Dispatch<React.SetStateAction<number>>;
  containerHeight: number;
  containerWidth: number;
}

const ConsultItem: React.FC<Props> = ({
  data,
  index,
  curActive,
  setCurActive,
  containerHeight,
  containerWidth,
}) => {
  const { title, icon, list } = data;
  const isActive = curActive === index;
  const isOtherActive = !isActive && curActive !== -1;
  const itemRef = useRef<HTMLElement>(null);

  const onOpenHandler = () => {
    if (isActive) return;
    setCurActive(index);
  };

  const onCloseHandler = () => {
    setCurActive(-1);
  };

  const Icon = () => {
    switch (icon) {
      case "blockChain":
        return <BlockChainSvg />;
      case "contract":
        return <ContractSvg />;
      case "money":
        return <MoneySvg />;
      case "marketing":
        return <MarketingSvg />;
      default:
        return null;
    }
  };

  let activeStyle: { [key in string]: any } = {
    height: `${containerHeight}px`,
    marginTop: 0,
    marginBottom: 0,
  };

  const isMediumScreen =
    window && window.innerWidth > 600 && window.innerWidth < 1000;
  const isLargeScreen = window && window.innerWidth > 1000;

  if (isMediumScreen) {
    activeStyle = {
      ...activeStyle,
      width: `${containerWidth - 128}px`,
    };
  } else if (isLargeScreen) {
    activeStyle = {
      ...activeStyle,
      width: `${containerWidth}px`,
    };
  }

  let infoListStyle: any = {};
  if (isMediumScreen) {
    if (!isActive) {
      infoListStyle.visibility = "hidden";
    } else if (list.length < 10) {
      infoListStyle.alignSelf = "center";
      infoListStyle.maxWidth = "70%";
    } else if (list.length > 9) {
      infoListStyle.maxWidth = "45%";
    }
  }

  return (
    <section
      ref={itemRef}
      className={`${classes.item} ${
        isOtherActive ? classes.fadeOut : isActive ? classes.isActive : ""
      }`}
      style={isActive ? activeStyle : {}}
      onClick={onOpenHandler}
    >
      <div className={classes.intro}>
        <Icon />
        <div className={classes.textBox}>
          <h3>{title}</h3>
        </div>
      </div>
      <ol className={classes.infoList} style={infoListStyle}>
        {list.map((d, i) => (
          <li className={classes.listItem} key={i}>
            {d}
          </li>
        ))}
      </ol>
      <span className={classes.number}>{"0" + (index + 1)}</span>
      <div
        className={`${classes.arrow} ${!isActive ? classes.hidden : ""}`}
        onClick={onCloseHandler}
      >
        <MoreSvg size={25} />
      </div>
      <div
        className={classes.doubleArrow}
        onClick={() => setCurActive((prev) => (prev === -1 ? index : -1))}
      >
        <MoreSvg />
        <MoreSvg />
      </div>
    </section>
  );
};

export default ConsultItem;
