import React from "react";

const MoreSvg: React.FC<{ size?: number }> = ({ size }) => {
  return (
    <svg
      width={size || "50"}
      height={size || "50"}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.8959 15.4375L27.4375 25L17.8959 34.5625L20.8334 37.5L33.3334 25L20.8334 12.5L17.8959 15.4375Z"
        fill="white"
      />
    </svg>
  );
};

export default MoreSvg;
